import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/bindUser',
  },
  {
    path: '/bindUser',
    component: () => import('@/views/bindUser'),
  },
  {
    path: '/census',
    component: () => import('@/views/census'),
  },
  {
    path: '/answer',
    component: () => import('@/views/answer'),
  },
  {
    path: '/error',
    component: () => import('@/views/error'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})

/* 路由拦截器 */

router.beforeEach((to, from, next) => {
  next()
})

export default router
